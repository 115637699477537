import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import truncate from 'lodash/truncate';

import Link from '../link';
import styles from './card-list.module.scss';

const CardList = ({ heading, moreLink, moreText, cards, className, type }) => {
  if (type !== 'Documents') {
    cards = cards.slice(0, 4);
  }
  return (
    <div className={classnames(styles.listContainer, className)}>
      <div className={styles.cardHeadings}>
        <div
          className={
            type === 'Documents' ? styles.cardDocHeading : styles.cardUpcoming
          }
        >
          {heading}
        </div>
        <Link
          to={moreLink}
          className={
            type === 'Documents' ? styles.cardDocMore : styles.cardMore
          }
        >
          {moreText}
        </Link>
      </div>
      {cards.map((card, index) => (
        <Link
          className={type === 'Documents' ? styles.cardDoc : styles.card}
          key={index}
          to={card.url}
        >
          {card.download ? (
            <div className={styles.cardDocData}>
              <span className={styles.cardUploadDate}>{card.createdShort}</span>
              <span className={styles.cardDocType}>{card.type}</span>
            </div>
          ) : (
            <img
              className={styles.cardImage}
              alt={card.smallImage.alt}
              src={card.smallImage.src}
            />
          )}
          <div className={styles.cardContent}>
            <span className={styles.cardTitle}>
              {type ? truncate(card.title, { length: 32 }) : card.title}
            </span>
            {type && (
              <>
                <span className={styles.cardUploadedBy}>
                  -<abbr title={card.authorName}>{card.uploadedBy}</abbr>
                </span>
              </>
            )}
            <div className={styles.cardDate}>
              {card.formattedDate ? card.formattedDate : card.startDate}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

CardList.propTypes = {
  cards: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  moreText: PropTypes.string.isRequired,
  moreLink: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.string
};

export default CardList;
